<template>
    <header class="header" id="header">
        <div class="_container">
            <div class="header__container">
                <router-link to="/" class="header__logo md">
                    <img :src="require(`@/assets/img/${logo}`)" alt="logo" />
                </router-link>
                <router-link to="/" class="header__to-main mm" v-if="$route.path === '/account'">
                    <img :src="require(`@/assets/img/svg/${arrow_back}`)" alt="arrow" width="10" />
                    <span>На главную</span>
                </router-link>
                <router-link to="/" class="mm logo_m" v-else>
                    <img :src="require(`@/assets/img/${logo_m}`)" alt="logo" />
                </router-link>
                <ul class="header__menu">
                    <a href="/#menu" class="header__item">Меню</a>
                    <a type="button" class="header__item" @click="$emit('openPopup'), setBlock()">Бронь столов</a>
                    <a href="/#about" class="header__item">О ресторане</a>
                    <a href="/#disc" class="header__item">Акции</a>
                    <a href="/#location" class="header__item">Как нас найти</a>
                    <a href="/#reviews" class="header__item">Отзывы</a>
                </ul>
                <div class="btn-mobile">
                    <button type="button" class="btn-mobile__burger">
                        <span></span>
                    </button>
                </div>
                <div class="mobile _mask">
                    <div class="mobile__block">
                        <div class="mobile__close">
                            <div class="mobile__btn-close">
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div class="mobile__menu">
                            <ul>
                                <li>
                                    <a href="/#menu" class="mobile__menu-item">Меню</a>
                                </li>
                                <li>
                                    <a type="button" class="mobile__menu-item" @click="$emit('openPopup'), setBlock()"
                                        >Забронировать стол</a
                                    >
                                </li>
                                <li>
                                    <a href="/#about" class="mobile__menu-item">О ресторане</a>
                                </li>
                                <li>
                                    <a href="/#disc" class="mobile__menu-item">Акции</a>
                                </li>
                                <li>
                                    <a href="/#reviews" class="mobile__menu-item">Отзывы</a>
                                </li>
                                <li>
                                    <a href="/#location" class="mobile__menu-item">Как нас найти</a>
                                </li>
                                <div class="mobile__ornament">
                                    <img src="@/assets/img/svg/ornament.svg" alt="ornament" width="62" height="12" />
                                </div>
                                <li>
                                    <button v-if="!username" class="mobile__menu-item" @click="popupLogin = true">
                                        <img :src="require(`@/assets/img/${user}`)" alt="account" />
                                        <span>Личный кабинет</span>
                                    </button>
                                    <router-link to="/account" v-else class="mobile__menu-item">
                                        <img :src="require(`@/assets/img/${user}`)" alt="account" />
                                        <span>{{ name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="header__login">
                    <button v-if="!username" class="header__account" @click="popupLogin = true">
                        <img :src="require(`@/assets/img/${user}`)" alt="account" />
                        <span>Личный кабинет</span>
                    </button>
                    <router-link to="/account" v-else class="header__account">
                        <img :src="require(`@/assets/img/${user}`)" alt="account" />
                        <span>{{ name }}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </header>
    <login-popup v-if="popupLogin" @click="popupLogin = !popupLogin" @auth="userAuth" @close="closeLogin" />
</template>

<script>
import LoginPopup from "@/components/popups/LoginPopup.vue";
import axios from "axios";
export default {
    components: { LoginPopup },
    name: "HeaderMain",
    data() {
        return {
            popupLogin: false,
            arrow_back: "arrow_back.svg",
            logo_m: "logo_m.png",
        };
    },
    props: ["username", "logo", "user", "name"],
    emits: {
        authData: null,
    },
    mounted() {
        let btnBurger = document.querySelector(".btn-mobile");
        let btnClose = document.querySelector(".mobile__btn-close");
        let mobileBlock = document.querySelector(".mobile__block");
        let wrapper = document.querySelector(".wrapper");
        let _mask = document.querySelector("._mask");
        btnBurger.addEventListener("click", function () {
            btnBurger.classList.add("_active-burger");
            _mask.classList.add("_show");
            mobileBlock.classList.add("_active-move");
            wrapper.style.height = "100vh";
        });
        function removeClass() {
            btnBurger.classList.remove("_active-burger");
            _mask.classList.remove("_show");
            mobileBlock.classList.remove("_active-move");
            wrapper.style.height = "100%";
        }
        btnClose.addEventListener("click", function () {
            removeClass();
        });
        _mask.addEventListener("click", function (e) {
            if (!mobileBlock.contains(e.target)) removeClass();
        });
        let mobileMenuItems = document.querySelectorAll(".mobile__menu-item");
        for (let mobileMenuItem of mobileMenuItems) {
            mobileMenuItem.addEventListener("click", function () {
                removeClass();
            });
        }
    },
    methods: {
        closeLogin() {
            this.popupLogin = false;
        },
        async userAuth(token) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/projects/tatar/profile/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                });
                console.log(response.data);
                localStorage.setItem("token", token);
                localStorage.setItem("username", response.data.username);
                localStorage.setItem("name", response.data.first_name);
                this.$emit("authData", response.data);
                this.popupLogin = false;
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },
        setBlock() {
            localStorage.setItem("block", "header");
        },
    },
};
</script>

<style lang="scss">
.logo_m {
    width: 88px;
    height: 40px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.header {
    width: 100%;
    height: 80px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    background-color: rgba(42, 55, 75, 0.17);
    @media (max-width: $ms2) {
        height: auto;
        ._container {
            padding: 0 20px;
        }
    }
    @media (max-width: $ms3) {
        height: 64px;
    }
    &__to-main {
        font-size: 14px;
        line-height: 24px;
        color: #e5eefa;
        img {
            margin-right: 10px;
        }
    }
    &__container {
        display: flex;
        align-items: center;
        padding: 2rem 0;
        position: relative;
        @media (max-width: $ms2) {
            flex-direction: column;
            padding: 1rem 0;
        }
        @media (max-width: $ms3) {
            flex-direction: unset;
            padding: 2rem 0;
        }
    }
    &__menu {
        flex: 1 1 auto;
        padding: 0 3rem;
        @media (max-width: $ms2) {
            margin-top: 1rem;
            width: 100%;
            padding: 0;
        }
        @media (max-width: $ms3) {
            display: none;
        }
    }
    &__item {
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #fff;
        padding: 0 1rem;
        font-family: "Montserrat";
        cursor: pointer;
        &:hover,
        &:focus {
            color: #ccc;
            text-shadow: 2px 2px 10px #000;
            transition: 0.2s ease 0.2s;
        }
    }
    &__logo {
        width: 106px;
        height: 48px;
        display: block;
        margin-bottom: -1rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__login {
        display: flex;
        align-items: center;
        @media (max-width: $ms2) {
            margin-top: 1rem;
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        @media (max-width: $ms3) {
            display: none;
        }
    }
    &__account {
        display: flex;
        align-items: center;
        font-weight: 400;
        background: none;
        font-size: 18px;
        font-weight: 300;
        line-height: 24.55px;
        text-align: center;
        color: #fefefe;
        margin-left: 2rem;
        font-family: "Montserrat";
        &:hover,
        &:focus {
            color: #ccc;
            text-shadow: 2px 2px 10px #000;
            transition: 0.2s ease 0.2s;
        }
        img {
            width: 30px;
            height: 30px;
            margin-right: 5px;
        }
    }
}
/* -------------------- MOBILE -------------------- */
.btn-mobile {
    display: none;
    position: absolute;
    right: 1rem;
    @media (max-width: $ms3) {
        display: block;
    }
    &__burger {
        background-color: transparent;
        display: flex;
        justify-content: flex-end;
        width: 23px;
        span {
            background: #fff;
            border-radius: 2px;
            width: 11px;
            height: 1px;
            &::before,
            &::after {
                background: inherit;
                content: "";
                position: absolute;
                right: 0;
                height: 1px;
            }
            &::before {
                top: 8px;
                width: 19px;
            }
            &::after {
                bottom: 8px;
                width: 100%;
            }
        }
    }
}
.mobile {
    &__block {
        width: 100%;
        height: 100vh;
        padding: 30px;
        background-color: #2a374b;
        position: absolute;
        z-index: 999;
        left: -100%;
        top: 0;
        display: flex;
        flex-direction: column;
        opacity: 0;
    }
    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
    }
    &__menu {
        margin-top: 50px;
    }
    &__btn-close {
        position: relative;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        span {
            position: absolute;
            background: #fff;
            width: 25px;
            height: 2px;
            &:first-child {
                transform: rotate(45deg);
            }
            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }
    &__menu {
        ul {
            flex: 1 1 auto;
            margin-top: 1rem;
            word-break: break-word;
            li {
                padding: 1rem 0;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
    &__menu-item {
        font-size: 20px !important;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: none;
        text-align: right;
        font-family: "Montserrat" !important;
        img {
            width: 30px;
            margin-left: 15px;
            margin-right: 15px;
            filter: invert(0) !important;
        }
    }
    &__ornament {
        display: flex;
        justify-content: flex-end;
        padding: 1.5rem 0;
    }
}
._active-burger {
    span {
        width: 0 !important;
        transition: width ease-in 0.1s;
        &::before {
            top: 0 !important;
            transition: top 0.1s ease-in;
            animation: rotateRight 0.2s forwards 0.2s;
        }
        &::after {
            bottom: 0 !important;
            transition: bottom 0.1s ease-in;
            animation: rotateLeft 0.2s forwards 0.2s;
        }
        @keyframes rotateRight {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(45deg);
            }
        }
        @keyframes rotateLeft {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(-45deg);
            }
        }
    }
}
._active-move {
    animation: moveLeft 0.2s forwards 0.2s;
    @keyframes moveLeft {
        0% {
            left: -100%;
            opacity: 0;
        }
        100% {
            left: 0;
            opacity: 1;
        }
    }
}
</style>
