<template>
    <div class="discounts" id="disc">
        <div class="_container">
            <div class="discounts__container">
                <h2 class="title">Акции и новости</h2>
                <div class="dishes">
                    <div
                        class="dish"
                        v-for="discount in discounts"
                        :key="discount.id"
                        :id="discount.id"
                        @click="
                            discount.id !== 2
                                ? token
                                    ? showPopupId(discount.id)
                                    : $emit('popupLogin')
                                : routerHrefLink()
                        "
                    >
                        <div class="dish__view">
                            <div class="dish__img">
                                <img :src="require(`@/assets/img/discounts/${discount.img}`)" :alt="discount.title" />
                            </div>
                        </div>
                        <div class="dish__body">
                            <h4 class="dish__title">{{ discount.title }}</h4>
                            <div class="dish__flex">
                                <div class="dish__text">
                                    {{ discount.text }}
                                </div>
                                <div class="dish__bottom">
                                    <button
                                        class="dish__more"
                                        @click="
                                            discount.id !== 2
                                                ? token
                                                    ? showPopupId(discount.id)
                                                    : $emit('popupLogin')
                                                : routerHrefLink()
                                        "
                                    >
                                        Подробнее
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <popup-mask-disc
        :class="popupMore ? '_show' : ''"
        @close="popupMore = false"
        v-for="discount in discounts"
        :key="discount.id"
        :popupId="popupId"
        :discount="discount"
    />
</template>

<script>
import PopupMaskDisc from "@/components/popups/PopupMaskDisc.vue";
export default {
    name: "DiscountsMain",
    components: { PopupMaskDisc },
    props: ["token"],
    data() {
        return {
            popupMore: false,
            popupId: 0,
            discounts: [
                {
                    id: 1,
                    title: `СИСТЕМА ЛОЯЛЬНОСТИ\nСети ресторанов «Tatar»`,
                    text: "В заведениях сети ресторанов Татар действует единая система лояльности.",
                    img: "1.png",
                    full_text: `
                        От каждого заĸаза 5% будет возвращаться Вам бонусами.\n
                        Сохраните ĸарту ĸ себе в телефон и рассчитывайтесь бонусами до 100%\n
                        <a href="https://clck.ru/3Ckct8" target="_blank">ОФОРМИТЬ КАРТУ</a>\n
                        Мы надеемся, что с данным сервисом поход в наши заведения будет еще удобнее! Все ваши пожелания вы можете написать в наш чат поддержки - <a href="https://t.me/TubatayHelp_bot" target="_blank">@TubatayHelp_bot</a>
                    `,
                },
                {
                    id: 2,
                    title: "Дарим 300 бонусов",
                    text: "Дарим 300 приветственных бонусов, чтобы попробовать блюда ресторана",
                    img: "telegram.png",
                    full_text: `Дарим 300 приветственных бонусов, чтобы попробовать блюда ресторана`,
                },
            ],
            telegram: "https://t.me/Tatar_tbtbot?start=link_EuH0QI6KnX",
        };
    },
    methods: {
        showPopupId(popupId) {
            console.log(popupId);
            this.popupId = popupId;
            this.popupMore = true;
        },
        routerHrefLink() {
            window.open(this.telegram, "_blank");
        },
    },
};
</script>

<style lang="scss">
.discounts {
    background-color: #fafafa;
    padding: 50px 0;
    .dishes {
        display: flex;
        justify-content: center;
        @media (max-width: $ms3) {
            flex-direction: column;
        }
    }
    .dish {
        margin-top: 35px;
        max-width: 432px;
        height: auto;
        @media (max-width: $ms3) {
            margin-top: 20px;
            max-width: unset;
            &__title {
                font-size: 18px;
            }
        }
        &__title {
            white-space: pre-line;
            flex: 1 1 auto;
        }
        &__flex {
            display: flex;
            align-items: flex-end;
            flex: 1 1 auto;
        }
        &__img {
            height: 254.77px;
            @media (max-width: $ms3) {
                height: 184px;
            }
        }

        &__text {
            margin-bottom: 0;
            padding-right: 20px;
            @media (max-width: $ms3) {
                font-size: 12px;
            }
        }

        &__bottom {
            display: flex;
        }
        &__more {
            font-family: "Montserrat", sans-serif;
            @media (max-width: $ms4) {
                font-size: 8px;
                line-height: 14px;
                text-decoration: underline;
            }
        }
        &__body {
            padding: 20px 30px 30px;
            @media (max-width: $ms4) {
                padding: 10px 10px 20px;
            }
        }
    }
}
.popup {
    &__title {
        white-space: pre-line;
    }
    &__img {
        height: 300px;
    }
    &__text {
        a {
            color: #000;
            text-decoration: underline;
        }
    }
}
</style>
